<template>
  <v-container fluid>
    <v-row>
      <v-col class="align-center d-flex flex-column">
        <v-overlay :value="loading">
          <v-progress-circular size="120" indeterminate
            >Cadastrando...
          </v-progress-circular>
        </v-overlay>
        <v-card class="col-md-5 col-sm-12 col-lg-5">
          <v-card-title class="title-login d-flex justify-center mb-3">
            <h2 color="#0d47a1">Cadastro</h2>
          </v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="12">
                  <v-text-field
                    class="mb-2"
                    v-model="form.nome"
                    :rules="[rules.required]"
                    label="Como quer ser chamado?"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    class="mb-2"
                    v-model="maskedCpf"
                    :rules="[rules.required]"
                    v-mask="['###.###.###-##']"
                    label="CPF"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    class="mb-2"
                    v-model="maskedNumber"
                    v-mask="['(##) #####-####']"
                    label="Telefone"
                    placeholder="(99) 99999-9999"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    class="mb-2"
                    v-model="form.email"
                    :rules="[rules.required, rules.emailCometa]"
                    label="E-mail"
                    type="email"
                    placeholder="informe um e-mail @viacometa.com.br"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    class="mb-8"
                    v-model="form.senha"
                    :rules="[rules.required, rules.password]"
                    label="Senha"
                    :type="passwordInput"
                    :append-icon="passwordInputIcon"
                    @click:append="showPassword"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-row justify="center">
            <v-col cols="12" md="3" sm="6" lg="3">
              <v-btn
                class="white--text"
                block
                @click="cadastrar"
                color="#0d47a1"
                >{{ loading ? "Carregando" : "Cadastrar" }}</v-btn
              >
            </v-col>
            <v-col cols="12" md="3" sm="6" lg="3">
              <v-btn block color="primary" outlined :to="{ name: 'login' }"
                >Voltar</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import rules from "@/mixins/inputRules";
import sistema from "@/services/http/sistemaService";

export default {
  name: "CadastroUsuario",

  mixins: [rules],

  data() {
    return {
      form: {},
      maskedCpf: "",
      maskedNumber: "",
      show: false,
      passwordInput: "password",
      passwordInputIcon: "mdi-eye-off",
      loading: false
    };
  },

  watch: {
    maskedCpf(value) {
      this.form.cpf = value.replace(/[^\d]/g, "");
    },

    maskedNumber(value) {
      this.form.telefone = value.replace(/[^\d]/g, "");
    }
  },

  methods: {
    showPassword() {
      this.show = !this.show;
      this.passwordInputIcon = this.show ? "mdi-eye" : "mdi-eye-off";
      this.passwordInput = this.show ? "text" : "password";
    },

    async cadastrar() {
      if (this.$refs.form.validate()) {
        this.loading = true;

          await sistema()
          .usuario()
          .store(this.form, {
            notification: true,
            message: "Usuário cadastrado, verifique sua caixa de e-mail"
          });
        this.loading = false;
      }
    },
    goToHome() {
      this.$router.push("/login");
    }
  }
};
</script>
